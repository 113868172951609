<template>
    <div>
        <div class="header" id="header">
            <img src="https://zhongyinwh.oss-cn-beijing.aliyuncs.com/scott/pic/组763_1686894590711.png" class="hwlogo" @click="toindex">
            <div class="headericon" id="headericon" @click="headericon"></div>
        </div>
        <div class="headerhidden" id="headerhidden">
            <div class="headeritem" @click="toindex"> <span> 首页 </span> </div>
            <div class="headeritem" v-for="(item) in columnData" :key="item.id" @click="toLink(item.url)">
                <span>{{item.columnName}}</span>
            </div>
        </div>
        <div style="height: 1.08rem;"></div>




        <div class="topbox">

        <div class="movieitem">
            <div class="movieimg"  v-if="movieDetail">
                <img :src="movieDetail.squarePic">
            </div>
            <div class="movietxtbox"  v-if="movieDetail">
                <img v-if="isShoucang"  src="https://zhongyinwh.oss-cn-beijing.aliyuncs.com/scott/pic/加关注_1686728934219.png" class="shoucangImg" @click="toShoucang">
                <img v-else  src="https://zhongyinwh.oss-cn-beijing.aliyuncs.com/scott/pic/加关注(1)_1686729283511.png" class="shoucangImg" @click="noShoucang">


                <p class="movietitle">{{movieDetail.movie.slice(1,-1)}}</p>
                <!-- <p class="movietxt">导演：{{movieDetail.infoSource}}</p>
                <div class="movietxt2">
                    <div>主演：</div>
                    <p>{{movieDetail.infoSource}}</p>
                </div> -->
                <p class="movietxt">类型：{{movieDetail.genres}}</p>
                <p class="movietxt">上映时间：{{movieDetail.releaseDateText}}</p>
                <p class="movietxtcompanyName">项目方：{{movieDetail.companyName}}</p>

            </div>
        </div>


        <!-- <div class="xmedBox">
            <div class="boxtitle" style="marginBottom:.7rem;">
                项目额度
            </div>
            <div class="xmedBox1">
                <div class="xmedtiaoBox">
                    <div class="xmedtiao" style="width:calc(5.16rem * .6);"></div>
                </div>
                <p class="xmedTxt">当前进度60%</p>
            </div>

        </div> -->


        <div class="btnBox">
            <div class="xxbtn" @click="showDialog2">项目公告</div>
            <!-- <div class="xxbtn" @click="showDialog">备案查询</div> -->
            <div class="xxbtn" @click="showDialog">分析报告</div>
            <!-- <div class="xxbtn" @click="showDialog">尽调报告</div> -->
        </div>

        <div class="xmxxbox" v-if="srcList">
            <div class="boxtitle">
                项目物料
            </div>
            <div class="ypwlbox">
                <!-- <div class="ypwlboxs">
                    <img v-for="(item) in materialList" :key="item.id" :src="item.cover">
                </div> -->
                
                <div class="ypwlboxs">
                    <el-image 
                        v-for="(item) in materialList" 
                        :key="item.id" 
                        :src="item.cover"
                        :preview-src-list="srcList">
                    </el-image>
                </div>
            </div>
        </div>

        <div class="xmfxxbox" v-if="companyList1&&companyList2">
            <div class="boxtitle">
                参与公司
            </div>
            <div class="xmfxxlist">
                <div class="xmfxxlists">
                    <div class="cplist">
                        <p class="cplistTitle">出品</p>
                        <div class="cplists">

                            <router-link v-for="(item) in companyList1" :key="item.companyId" :to="{name:'companydetail',params:{companyId:item.companyId}}">
                                <div class="cplistsItem">
                                    <img :src="item.logo">
                                    <p>{{item.companyName}}</p>
                                </div>
                            </router-link>

                        </div>
                    </div>
                    <div class="cplist">
                        <p class="cplistTitle">联合出品</p>
                        <div class="cplists">
                            <router-link v-for="(item) in companyList2" :key="item.companyId" :to="{name:'companydetail',params:{companyId:item.companyId}}">
                                <div class="cplistsItem">
                                    <img :src="item.logo">
                                    <p>{{item.companyName}}</p>
                                </div>
                            </router-link>

                        </div>
                    </div>
                </div>
            </div>
        </div>


        <div class="rmxmbox" v-if="qualificationList">
            <div class="boxtitle">
                资质查询
            </div>

            <div class="zzcxList">
                <el-image 
                    :src="qualificationList[0].qualificationUrl"
                    :preview-src-list="qualificationLists">
                </el-image>
            </div>
        </div>


        <!-- <div class="xmxxbox" v-if="movieDetail.financingStatus != 1">
            <div class="boxtitle">
                项目信息
                <img src="../../assets/img/titlebg.png" alt="">
            </div>
            <div class="xmxx">
                <div class="xmxx1">
                    <p>
                        <span>总票房：</span>
                        <span>{{movieDetail.predictBox | costFilter}}</span>
                    </p>
                </div>
                <div class="xmxx2">
                    <p>
                        <span>上映时间：</span>
                        <span>{{movieDetail.showTime}}</span>
                    </p>
                </div>
            </div>
        </div> -->








    </div>

    <div class="topboxbottom"></div>


    <div class="navbox">
        <div class="nav">
            <div class="navs">
                <div class="navitem navactive" @click="tonavitem1">影视简介</div>
                <div class="navitem" @click="tonavitem2">演员阵容</div>
                <!-- <div class="navitem" @click="tonavitem3">认购流程</div> -->
                <!-- <div class="navitem">资质查询</div> -->
                <div class="navitem" @click="tonavitem4">影片亮点</div>
                <!-- <div class="navitem" @click="tonavitem5">热门项目</div> -->
                <div class="navitem" @click="tonavitem6">影片进度</div>
            </div>
            <div class="navxianxx"></div>
        </div>
    </div>





    <div class="xsjjbox">
        <div class="boxtitle">
            影视简介
        </div>
        <div class="xsjjtxt"  v-if="movieDetail">
            {{movieDetail.projectIntro}}
        </div>
    </div>



    <div class="yyzrbox"  v-if="celebrityList">
        <div class="boxtitle">
            演员阵容
        </div>
        <div class="yyzrList">
            <div class="yyzrLists">

                <div class="yyzrItem" v-for="(item) in celebrityList" :key="item.celebrityId" @click="showCloseyyDetailModal(item.celebrityId)">
                    <img :src="item.avatar">
                    <p>{{item.name}}</p>
                    <p class="yyzrItemCastPosition">{{item.castPosition_dictText}}</p>

                </div>

            </div>
        </div>
        <p class="yyzrboxMSG">(提示：点击人物头像可查看详细信息)</p>
    </div>



    <!-- <div class="cglcbox">
        <div class="boxtitle">
            认购流程
            <img src="../../assets/img/titlebg.png" alt="">
        </div>
        <img src="https://yhscimg.hbyzys.cn/rglc.png" alt="" class="cglcimg">
    </div> -->


    <!-- <div class="zzcxbox">
        <div class="boxtitle">
            资质查询
            <img src="../../assets/img/titlebg.png" alt="">
        </div>
        <div class="zzcximgbox">
            <img src="../../assets/img/zzcximg.png" alt="">
        </div>
    </div> -->


    <div class="xgyxbox" v-if="highlightsText">
        <div class="boxtitle">
            影片亮点
        </div>

        <div class="xgyxList" v-html="highlightsText"></div>

    </div>



    



    <div class="cgdybox" v-if="scheduleList&&scheduleFirst">
        <div class="boxtitle">
            影片进度
        </div>
        <div class="ypjdList">

            <div class="ypjdItem">
                <div class="ypjdItemTop">
                    <div class="ypjdItemTopPoint">
                        <div class="ypjdItemTopPointS"></div>
                    </div>
                    <p>{{scheduleFirst.scheduleTime | dataFilter}}</p>
                </div>
                <div class="ypjdItemBottom">
                    <div class="ypjdItemBottomLine"></div>
                    <div class="ypjdItemBottomP">{{scheduleFirst.content}}</div>
                </div>
            </div>

            <div class="ypjdItem" v-for="(item) in scheduleList" :key="item.id">
                <div class="ypjdItemTop">
                    <div class="ypjdItemTopPoint2">
                        <div class="ypjdItemTopPointS2"></div>
                    </div>
                    <p>{{item.scheduleTime | dataFilter}}</p>
                </div>
                <div class="ypjdItemBottom">
                    <div class="ypjdItemBottomLine"></div>
                    <div class="ypjdItemBottomP">{{item.content}}</div>
                </div>
            </div>

        </div>


    </div>





    <div class="searchbox close-searchbox">


        <div class="modal-dialog close-modal-dialog animated bounceIn" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="close" @click="closeSearch">
                        <span>×</span></button>
                    <h4 class="modal-title" id="myModalLabel">
                        登录</h4>
                </div>
                <div class="modal-body">
                    <div class="input-group">
                        <input id="k" placeholder="请填入您的手机号码" v-model="phone">
                    </div>
                    <div class="input-group">
                        <input id="p" placeholder="请输入验证码" v-model="code">
                        <div class="sendcode" @click="sendcode">发送验证码</div>
                    </div>
                    <span class="btn" @click="doSubmit()">登录</span>
                </div>
            </div>
        </div>


    </div>



    <div class="yyDetailBox close-yyDetailBox">
        <div class="yyDetailModal">
            <button type="button" class="closeyyDetailModal" @click="closeyyDetailModal">
                <span>×</span>
            </button>
            <div class="yyDetailModalTop">
                <img :src="movieCelebrity.avatar">
                <div class="yyDetailModalTopTxt">
                    <p class="yyDetailModalTopTxt1">{{movieCelebrity.name}}</p>
                    <!-- <p class="yyDetailModalTopTxt2">性别: {{movieCelebrity.name}}</p> -->
                    <p class="yyDetailModalTopTxt2">职业: {{movieCelebrity.profession}}</p>
                    <p class="yyDetailModalTopTxt2" v-if="movieCelebrity.birthday">出生日期: {{movieCelebrity.birthday}}</p>
                </div>
            </div>
            <div class="yyDetailModalBottom" v-html="movieCelebrity.intro"></div>
        </div>
    </div>

    <el-dialog
        :visible.sync="dialogVisible"
        width="90%">
        <span>
            <p style="textAlign:center;fontSize:.4rem;marginBottom:.4rem;color: #AE1F20;font-weight: bold;">获取项目详细信息</p>
            <el-form ref="form" :model="form" label-width="80px">
                <el-form-item label="电话号码:">
                    <el-input v-model="form.phone"></el-input>
                </el-form-item>
                <el-form-item label="项目名称:">
                    <el-input v-model="form.movie"></el-input>
                </el-form-item>
            </el-form>

        </span>
        
        <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button type="danger" @click="onOK">提 交</el-button>
        </span>
    </el-dialog>


    <el-dialog
        :visible.sync="dialogVisible2"
        width="90%">
        <span>
            <p style="font-size: .42rem;font-weight: bold;color: #343434;textAlign:center;letterSpacing: .02rem;marginBottom:.9rem;">项目公告</p>

            <div v-html="projectAnnouncement" style="marginBottom:.9rem;height:5.6rem;">

            </div>


            <div @click="dialogVisible2 = !dialogVisible2" style="box-sizing: border-box;padding:0 .2rem;width:100%;height:.88rem;background: #AE1F20;border-radius: .12rem;textAlign:center;lineHeight:.88rem;font-size:.36rem;color: #FFFFFF;">确定</div>

        </span>
        
        <!-- <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button type="danger" @click="onOK">提 交</el-button>
        </span> -->
    </el-dialog>


    <Foot />




    <!-- <div style="height: 0.82rem;"></div>
    <div class="toxmrg" @click="toGoXmrg">
            项目认购
    </div> -->




    <div class="toGrzx" @click="toGrzx">
        <img src="https://zhongyinwh.oss-cn-beijing.aliyuncs.com/scott/pic/个人中心(1)(1)_1686566476728.png" class="toGrzxImg">
    </div>
    
    
    
    
    </div>




    
</template>


<script>
import Foot from "@/components/foot.vue"
import Cookies from 'js-cookie'
import {getListColumn,queryDetailByProjectId,listMaterialByProjectId,listQualificationByProjectId,listCompanyByProjectId,listCelebrityByProjectId,listScheduleByProjectId,listHighlightsText,movieCelebrityQueryById,addForm,addOrUpdateUserInterest,getUserInterestResult} from "@/api/index"
import store from '@/store'
import {Loading,Message} from 'element-ui';
export default {
    components:{
        Foot
    },
    data(){
        return {
            srcList:[],
            form:{
                phone:"",
                movie:""
            },
            dialogVisible:false,
            dialogVisible2:false,
            topnavaindex : 0,
            navtop : null,
            editContent1 : null,
            editContent2 : null,
            movieDetail : {
                "movie":"",
            },
            moviefrom : null,
            qalist : null,
            articlelist : null,
            headerflag : false,
            hiddenflag : false,
            headheight : null,
            topheight : null,
            xsjjboxtop : null,
            yyzrboxtop : null,
            cglcboxtop : null,
            xgyxboxtop : null,
            rmxmboxtop : null,
            cgdyboxtop : null,
            load : null,
            ywltoken:null,
            flag:false,
            phone:"",
            code:"",
            yzmt:null,
            yzms:60,
            yzmflag:true,
            columnData:null,
            materialList:null,
            qualificationList:null,
            qualificationLists:[],
            companyList1:null,
            companyList2:null,
            celebrityList:[
                {
                    "avatar": "",
                    "castPosition": 0,
                    "celebrityId": "123",
                    "ename": "",
                    "name": "",
                    "roleText": ""
                }
            ],
            scheduleList:null,
            scheduleFirst:null,
            highlightsText:null,
            projectAnnouncement:"",
            movieCelebrity:{
                "avatar": "",
                "boxOffice": 0,
                "createBy": "",
                "createTime": "",
                "delFlag": "",
                "ename": "",
                "fansNum": 0,
                "intro": "",
                "name": "",
                "profession": "",
                "status": 0,
                "sysOrgCode": "",
                "topicNum": 0,
                "updateBy": "",
                "updateTime": "",
                "worksNum": 0
            },
            xmId:1,
            wjsToken: null,
            wjsUserInfo: null,
            isShoucang:1,
        }
    },
    computed: {
        columnList() {
            return this.$store.getters.columnList
        },
    },
    methods:{
        toShoucang(){
            if(!this.wjsToken){
                this.showErr("请登录")
                this.toLink('dl')
                return
            }
            addOrUpdateUserInterest({
                "interestObjId": this.$route.params.movieId,
                "interestType": 1
            }).then(res => {
                if(res.code == 200){
                    this.isShoucang = 0;
                    this.showSuccess(res.message)
                }else{
                    this.showErr(res.message)
                }
                
            })
            
        },
        noShoucang(){
            if(!this.wjsToken){
                this.showErr("请登录")
                this.toLink('dl')
                return
            }
            addOrUpdateUserInterest({
                "interestObjId": this.$route.params.movieId,
                "interestType": 1
            }).then(res => {
                if(res.code == 200){
                    this.isShoucang = 1;
                    this.showSuccess(res.message)
                }else{
                    this.showErr(res.message)
                }
                
            })

        },
        toGrzx(){
            if(!Cookies.get('wjsToken')){
                this.$router.push({name:'dl'});
            }else{
                this.$router.push({name:'grzx'});
            }
        },
        toLink(name){
            //if(name == "yszb"){
                //window.location.href="http://www.zygart.cn"; 
            //}else{
                this.reset()
                this.$router.push({name:name});
            //}
        },
        toBack(){
            this.reset()
            // if(this.$route.params.linkType && this.$route.params.linkType == 1){
            //     this.$router.push({name:'index'});
            // }else if(this.$route.params.linkType && this.$route.params.linkType == 2){
            //     this.$router.push({name:'movielist'});
            // }else{
            //     this.$router.push({name:'index'});
            // }
            this.$router.back(-1)
        },

        toindex(){
            this.reset()
            this.$router.push({name:'index'});
        },
        
        getElementTop(el) {
            var actualTop = el.offsetTop
            var current = el.offsetParent
            while (current !== null) {
                actualTop += current.offsetTop
                current = current.offsetParent
            }
            return actualTop
        },
        windowscroll(){
            var t = document.documentElement.scrollTop || document.body.scrollTop;
            var nav = document.getElementsByClassName("nav")[0];
            var vw = document.documentElement.clientWidth / 7.5;
            this.headheight = document.documentElement.clientWidth / 7.5 * 1.08;
            this.topheight = document.documentElement.clientWidth / 7.5 * 1.68;
            var xsjjbox = document.getElementsByClassName("xsjjbox")[0];
            this.xsjjboxtop = this.getElementTop(xsjjbox) - this.topheight;
            var yyzrbox = document.getElementsByClassName("yyzrbox")[0];
            this.yyzrboxtop = this.getElementTop(yyzrbox) - this.topheight;
            //var cglcbox = document.getElementsByClassName("cglcbox")[0];
            //this.cglcboxtop = this.getElementTop(cglcbox) - this.topheight;
            // var zzcxbox = document.getElementsByClassName("zzcxbox")[0];
            // var zzcxboxtop = this.getElementTop(zzcxbox) - topheight;
            var xgyxbox = document.getElementsByClassName("xgyxbox")[0];
            this.xgyxboxtop = this.getElementTop(xgyxbox) - this.topheight;
            // var rmxmbox = document.getElementsByClassName("rmxmbox")[0];
            // this.rmxmboxtop = this.getElementTop(rmxmbox) - this.topheight;
            var cgdybox = document.getElementsByClassName("cgdybox")[0];
            this.cgdyboxtop = this.getElementTop(cgdybox) - this.topheight;


            if( t >= this.navtop){
                nav.classList.add("navfixed")
            }else{
                nav.classList.remove("navfixed")
            }



            if(t >= this.xsjjboxtop - 30 && t < this.yyzrboxtop - 30){
                this.topnavaindex = 0;
                this.navactive(this.topnavaindex)
            }
            if(t >= this.yyzrboxtop - 30&& t < this.xgyxboxtop - 30){
                this.topnavaindex = 1;
                this.navactive(this.topnavaindex)
            }
            // if(t >= this.cglcboxtop - 30&& t < this.xgyxboxtop - 30){
            //     this.topnavaindex = 2;
            //     this.navactive(this.topnavaindex)
            // }
            // if(t >= zzcxboxtop - 30&& t < xgyxboxtop - 30){
            //     this.topnavaindex = 3;
            //     this.navactive(this.topnavaindex)
            // }
            // if(t >= this.xgyxboxtop - 30&& t < this.rmxmboxtop - 30){
            //     this.topnavaindex = 2;
            //     this.navactive(this.topnavaindex)
            // }
            if(t >= this.xgyxboxtop - 30&& t < this.cgdyboxtop - 30){
                this.topnavaindex = 2;
                this.navactive(this.topnavaindex)
            }
            if(t >= this.cgdyboxtop - 30){
                this.topnavaindex = 3;
                this.navactive(this.topnavaindex)
            }


            // if(this.topnavaindex == 0){
            //     if(nav.scrollLeft > 0){
            //         $('.nav').animate({
            //             scrollLeft: 0
            //         }, 50);
            //     }
            // }

            // if(this.topnavaindex == 1){
            //     if(nav.scrollLeft > 74){
            //         $('.nav').animate({
            //             scrollLeft: 74
            //         }, 50);
            //     }
            // }

            // if(this.topnavaindex == 5){
            //     if(nav.scrollLeft < 66){
            //         $('.nav').animate({
            //             scrollLeft: 66
            //         }, 50);
            //     }
            // }

            // if(this.topnavaindex == 6){
            //     if(nav.scrollLeft < 143){
            //         $('.nav').animate({
            //             scrollLeft: 143
            //         }, 50);
            //     }
            // }
        },
        getElementTop(el) {
            var actualTop = el.offsetTop
            var current = el.offsetParent
            while (current !== null) {
                actualTop += current.offsetTop
                current = current.offsetParent
            }
            return actualTop
        },
        navactive(Index) {
            var navitem = document.getElementsByClassName("navitem");
            var navxianxx = document.getElementsByClassName("navxianxx")[0];
            for (let i = 0; i < navitem.length; i++) {
                navitem[i].classList.remove('navactive')
            }
            navitem[Index].classList.add('navactive')
            navxianxx.style.left = `calc(0.22rem * ${Index * 2 + 1} + 1.04rem * ${Index} + 0.52rem - 0.23rem)`
        },
        headericon(){
            var headericon = document.getElementById("headericon")
            var headerhidden = document.getElementById("headerhidden")
            if(this.headerflag){
                headerhidden.style.top = "-100%"
                headericon.classList.remove("headericon2")
                headericon.classList.add("headericon")
                this.headerflag = false
            }else{
                headerhidden.style.top = "1.06rem"
                headericon.classList.remove("headericon")
                headericon.classList.add("headericon2")
                this.headerflag = true
            }
        },
        headjt(){
            var headjt = document.getElementById("headjt")
            var hiddenbox = document.getElementById("hiddenbox")
            if(this.hiddenflag){
                hiddenbox.style.height = "0"
                headjt.style.transform = "rotateZ(0deg)"
                this.hiddenflag = false
            }else{
                hiddenbox.style.height = "4rem"
                headjt.style.transform = "rotateZ(-180deg)"
                this.hiddenflag = true
            }
        },
        reset(){
            this.headerflag = false
            this.hiddenflag = false
            var headericon = document.getElementById("headericon")
            var headerhidden = document.getElementById("headerhidden")
            //var headjt = document.getElementById("headjt")
            //var hiddenbox = document.getElementById("hiddenbox")
            headerhidden.style.top = "-100%"
            headericon.classList.remove("headericon2")
            headericon.classList.add("headericon")
            //hiddenbox.style.height = "0"
            //headjt.style.transform = "rotateZ(0deg)"
        },
        tonavitem1(){
            $('html,body').animate({
                scrollTop: this.xsjjboxtop
            }, 600);
        },
        tonavitem2(){
            $('html,body').animate({
                scrollTop: this.yyzrboxtop
            }, 600);
        },
        // tonavitem3(){
        //     $('html,body').animate({
        //         scrollTop: this.cglcboxtop
        //     }, 600);
        // },
        tonavitem4(){
            $('html,body').animate({
                scrollTop: this.xgyxboxtop
            }, 600);
        },
        // tonavitem5(){
        //     $('html,body').animate({
        //         scrollTop: this.rmxmboxtop
        //     }, 600);
        // },
        tonavitem6(){
            $('html,body').animate({
                scrollTop: this.cgdyboxtop
            }, 600);
        },
        showLoading(){
            this.load && this.load.close(); 
            this.load = Loading.service({
                lock: true,
                text: '加载中...',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
        },
        showlogin(){
            document.getElementsByClassName("modal-dialog")[0].classList.remove("close-modal-dialog")
            document.getElementsByClassName("searchbox")[0].classList.remove("close-searchbox")
            document.body.style.overflow='hidden'
        },

        closeSearch(){
            document.getElementsByClassName("modal-dialog")[0].classList.add("close-modal-dialog")
            document.getElementsByClassName("searchbox")[0].classList.add("close-searchbox")
            document.body.style.overflow='visible'
            // if(!this.flag){
            //     setTimeout(()=>{
            //         document.getElementsByClassName("modal-dialog")[0].classList.remove("close-modal-dialog")
            //         document.getElementsByClassName("searchbox")[0].classList.remove("close-searchbox")
            //         document.body.style.overflow='hidden'
            //     },10000)
            // }
        },
        doSubmit(){
            if (this.phone == "" || this.phone.replace(" ", "") == "") {
                alert("请填写您的手机号");
                return;
            }
            if (!(/^1[23456789]\d{9}$/.test(this.phone))) {
                alert("请输入有效的手机号码");
                return;
            }
            if (this.code == "" || this.code.replace(" ", "") == "") {
                alert("请填写验证码");
                return;
            }

            this.showLoading()

            loginBySms({
                "phoneNum": this.phone,
                "smsCode": this.code,
                "source": "引未来官网"
            }).then(res => {
                console.log(res.token)
                this.load && this.load.close();
                Message.success({
                    message: "登录成功",
                    center: true,
                    duration:1200,
                    showClose: true,
                })
                Cookies.set('ywltoken', res.token, { expires: 7 })
                this.ywltoken = res.token;

            }).catch(res => {
                console.log(res.message)
            })


        },
        showErr(txt){
            this.load && this.load.close(); 
            this.load = Loading.service({
                lock: true,
                text: txt || '网络错误',
                spinner: 'el-icon-circle-close',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            setTimeout(() => {
                this.load && this.load.close(); 
            },2000)
        },
        showSuccess(txt){
            this.load && this.load.close(); 
            this.load = Loading.service({
                lock: true,
                text: txt || '成功',
                spinner: 'el-icon-circle-check',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            setTimeout(() => {
                this.load && this.load.close(); 
            },2000)
        },
        sendcode(){
            var yzmbtn = document.getElementsByClassName("sendcode")[0];
            if (!this.phone) {
                alert("请填写您的手机号");
                return;
            }
            if (!(/^1[23456789]\d{9}$/.test(this.phone))) {
                alert("输入的手机号码不正确，请重新输入");
                return;
            }


            if(this.yzmflag){
                this.yzms = 60;
                this.yzmflag = false;

                sendCode({
                    "phoneNum": this.phone
                }).then(res => {
                    console.log(res)
                })


                this.yzmt = setInterval(() => {
                    //console.log(this.yzms)
                    
                    this.yzms--;
                    yzmbtn.innerHTML = this.yzms + "s";

                    if(this.yzms < 0){
                        clearInterval(this.yzmt)
                        this.yzmt = null;
                        yzmbtn.innerHTML = "获取验证码";
                        this.yzmflag = true;
                    }
                    
                    
                }, 1000);
            }else{

            }
        },
        closeyyDetailModal(){
            document.getElementsByClassName("yyDetailBox")[0].classList.add("close-yyDetailBox")
            //document.getElementsByClassName("searchbox")[0].classList.add("close-searchbox")
            document.body.style.overflow='visible'
        },
        showCloseyyDetailModal(index){
            movieCelebrityQueryById({
                "id":index
            }).then(res => {
                this.movieCelebrity = res.result;
                document.getElementsByClassName("yyDetailBox")[0].classList.remove("close-yyDetailBox")
                document.body.style.overflow='hidden'
            })
            
        },
        showDialog(){
            this.dialogVisible = true;
        },
        showDialog2(){
            this.dialogVisible2 = true;
        },
        onOK(){

            
            
            if(!this.form.phone){
                Message.error({
                    message: "请填写联系电话",
                    center: true,
                    duration:2000,
                    showClose: false,
                })
                return
            }

            if (!(/^1[23456789]\d{9}$/.test(this.form.phone))) {
                Message.error({
                    message: "输入的手机号码不正确，请重新输入",
                    center: true,
                    duration:2000,
                    showClose: false,
                })
                return;
            }

            if(!this.form.movie){
                Message.error({
                    message: "请填写项目名称",
                    center: true,
                    duration:2000,
                    showClose: false,
                })
                return
            }

            this.showLoading()

            addForm({
                "pageType": 0,
                "phone": this.form.phone,
                "postscript": this.form.movie
            }).then(res => {
                this.form.phone = "";
                this.load && this.load.close();
                if(res.code == 200){
                    this.showSuccess(res.message)
                }else{
                    this.showErr(res.message)
                }

            })




            this.dialogVisible = false;

        },
        toGoXmrg(){
            if(!this.wjsToken){
                this.showErr("请登录")
                this.toLink('dl')
                return
            }

            this.$router.push({path:`/xmrg/${this.xmId}`});
            
        }


    },
    mounted(){
            //console.log(this.$route.params.movieId)


            this.wjsToken =  Cookies.get('wjsToken') || null;
            this.wjsUserInfo =  Cookies.get('wjsUserInfo') || null;

            console.log(this.wjsToken)
            console.log(this.wjsUserInfo)

            
            this.showLoading()


            if(this.columnList){
                console.log("store" + this.columnList)
                this.columnData = this.columnList;
                this.load && this.load.close();
            }else{
                getListColumn().then(res => {
                    //console.log(res)
                    this.columnData = res.result;

                    store.dispatch('SetColumnList', res.result)


                })
            }


            if(this.$route.params.movieId){
                this.xmId = this.$route.params.movieId

                queryDetailByProjectId({
                    "id":this.$route.params.movieId
                }).then(res => {
                    this.movieDetail = res.result;
                    this.form.movie = res.result.movie;
                    console.log(this.movieDetail)
                    listMaterialByProjectId({
                        "projectId":this.$route.params.movieId
                    }).then(res2 => {
                        this.materialList = res2.result;
                        for(let j = 0;j < res2.result.length;j++){
                            this.srcList = this.srcList.concat(res2.result[j].cover)
                        }
                        //console.log(this.srcList)
                        listQualificationByProjectId({
                            "projectId":this.$route.params.movieId
                        }).then(res3 => {
                            this.qualificationList = res3.result;
                            this.qualificationLists = this.qualificationLists.concat(res3.result[0].qualificationUrl)
                            listCompanyByProjectId({
                                "projectId":this.$route.params.movieId
                            }).then(res4 => {
                                this.companyList1 = res4.result.records.filter((item) => item.participationType == 0);
                                this.companyList2 = res4.result.records.filter((item) => item.participationType == 1);
                                listCelebrityByProjectId({
                                    "projectId":this.$route.params.movieId
                                }).then(res5 => {
                                    this.celebrityList = res5.result.records;
                                    listScheduleByProjectId({
                                        "projectId":this.$route.params.movieId
                                    }).then(res6 => {
                                        this.scheduleFirst = res6.result[0];
                                        this.scheduleList = res6.result.splice(1,res6.result.length);
                                        listHighlightsText({
                                            "projectId":this.$route.params.movieId
                                        }).then(res7 => {
                                            if(res7.result.highlightsText){
                                                this.highlightsText = res7.result.highlightsText;
                                                this.projectAnnouncement = res7.result.projectAnnouncement;
                                            }

                                            
                                            if(this.wjsToken){
                                                getUserInterestResult({
                                                    "data": {
                                                        "interestObjId": this.$route.params.movieId,
                                                        "interestType": 1
                                                    }
                                                }).then(res8 => {
                                                    if(res8.code == 200){
                                                        this.isShoucang = res8.result.interestStatus;
                                                    }


                                                })
                                            }
                                        
                                            
                                            
                                                this.$nextTick(() => {



                                                        var t = document.documentElement.scrollTop || document.body.scrollTop;
                                                        var nav = document.getElementsByClassName("nav")[0];
                                                        var vw = document.documentElement.clientWidth / 7.5;
                                                        this.headheight = document.documentElement.clientWidth / 7.5 * 1.08;
                                                        this.topheight = document.documentElement.clientWidth / 7.5 * 1.68;
                                                        this.navtop = getElementTop(nav) - this.headheight;


                                                        //var navitem = document.getElementsByClassName("navitem");
                                                        var navxianxx = document.getElementsByClassName("navxianxx")[0];

                                                        var xsjjbox = document.getElementsByClassName("xsjjbox")[0];
                                                        this.xsjjboxtop = getElementTop(xsjjbox) - this.topheight;
                                                        var yyzrbox = document.getElementsByClassName("yyzrbox")[0];
                                                        this.yyzrboxtop = getElementTop(yyzrbox) - this.topheight;
                                                        //var cglcbox = document.getElementsByClassName("cglcbox")[0];
                                                        //this.cglcboxtop = getElementTop(cglcbox) - this.topheight;
                                                        // var zzcxbox = document.getElementsByClassName("zzcxbox")[0];
                                                        // var zzcxboxtop = getElementTop(zzcxbox) - topheight;
                                                        var xgyxbox = document.getElementsByClassName("xgyxbox")[0];
                                                        this.xgyxboxtop = getElementTop(xgyxbox) - this.topheight;
                                                        // var rmxmbox = document.getElementsByClassName("rmxmbox")[0];
                                                        // this.rmxmboxtop = getElementTop(rmxmbox) - this.topheight;
                                                        var cgdybox = document.getElementsByClassName("cgdybox")[0];
                                                        this.cgdyboxtop = getElementTop(cgdybox) - this.topheight;
                                                        //this.topnavaindex = 0



                                                        window.addEventListener('scroll',this.windowscroll)
                                                        this.load && this.load.close();




                                                    
                                                })



                                            


                                        })

                                    })
                                })
                            })
                        })
                    })
                })
            }else{
                this.toindex();
            }






            document.body.scrollTop = document.documentElement.scrollTop = 0;


            


            


            function navactive(Index) {
                for (let i = 0; i < navitem.length; i++) {
                    navitem[i].classList.remove('navactive')
                }
                navitem[Index].classList.add('navactive')
                navxianxx.style.left = `calc(0.22rem * ${Index * 2 + 1} + 1.04rem * ${Index} + 0.52rem - 0.23rem)`
            }




            //获取元素距离顶部高度
            function getElementTop(el) {
                var actualTop = el.offsetTop
                var current = el.offsetParent
                while (current !== null) {
                    actualTop += current.offsetTop
                    current = current.offsetParent
                }
                return actualTop
            }




    },
    beforeDestroy (){
        window.removeEventListener('scroll',this.windowscroll)
    },
    filters:{
        costFilter:function(val){
            if (!val) return '*'
            var newval = String(val)
            if(newval.length <= 4 && val != 0){
                return newval + "万"
            }else{
                return newval/10000 + "亿"
            }
        },
        dataFilter:function(val){
            if (!val) return ''
            return val.substring(0,10)
        }
    }
}
</script>


<style lang="scss">

.el-button--danger{
    background-color: #AE1F20;
    border-color: #AE1F20;
}

.close-yyDetailBox{
    display: none;
}
.yyDetailBox{
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    z-index: 999;
    background-color: rgba(0,0,0,.5);
    transition: 1s ease;
    .yyDetailModal{
        position: fixed;
        margin: auto;
        inset: 0;
        z-index: 1000;
        width: 6.68rem;
        height: auto;
        overflow: hidden;
        font-size: 0.24rem;
        transition: 1s ease;
        text-align: justify;
        background: #fff;
        border-radius: .08rem;
        padding: .8rem .6rem;
        box-sizing: border-box;
        display: inline-table;
        .closeyyDetailModal{
            position: absolute;
            top: .12rem;
            right: .16rem;
            padding: 0;
            cursor: pointer;
            background: 0 0;
            border: 0;
            appearance: none;
            float: right;
            font-size: .42rem;
            font-weight: 700;
            color: #000;
            text-shadow: 0 1px 0 #fff;
            opacity: .2;
        }
        .yyDetailModalTop{
            display: flex;
            height: 1.76rem;
            margin-bottom: .4rem;
            img{
                height: 1.76rem;
                width: 1.76rem;
                display: block;
                object-fit: cover;
                border-radius: 50%;
                overflow: hidden;
                margin-right: .4rem;
            }
            .yyDetailModalTopTxt{
                .yyDetailModalTopTxt1{
                    font-size: .34rem;
                    font-weight: bold;
                    color: #343434;
                    margin-bottom: .16rem;
                }
                .yyDetailModalTopTxt2{
                    font-size: .24rem;
                    font-weight: 400;
                    color: #A1A1A1;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    margin-bottom: .08rem;

                }
            }
        }
        .yyDetailModalBottom{
            font-size: .24rem;
            color: #343434;
            line-height: .30rem;
        }
    }
    .close-yyDetailModal{
        display: none;
    }
}



.topbox{
    width: 100%;
    padding: 0.38rem 0.22rem 0.22rem;
    box-sizing: border-box;
    overflow: hidden;
    position: relative;
}
.movieitem{
    width: 100%;
    height: 2.7rem;
    display: flex;
    margin-bottom: 0.22rem;
}
.movieitem .movieimg{
    height: 2.7rem;
    width: 2.7rem;
    margin-right: 0.2rem;
    border-radius: 0.12rem;
}
.movieitem .movieimg img{
    height: 2.7rem;
    width: 2.7rem;
    object-fit: cover;
}
.movieitem .movietxtbox{
    width: calc(100% - 2.9rem);
    height: 100%;
    position: relative;
}
.movieitem .movietxtbox .shoucangImg{
    height: .34rem;
    width: .34rem;
    position: absolute;
    top: .12rem;
    right: 0rem;
}
.movieitem .movietxtbox .movietitle{
    width: 3.8rem;
    font-size: 0.42rem;
    font-weight: bold;
    line-height: 0.54rem;
    color: #252525;
    margin-bottom: 0.1rem;
    display: -webkit-box;    
    -webkit-box-orient: vertical;    
    -webkit-line-clamp: 2;    
    overflow: hidden;
}
.movieitem .movietxtbox .movietxt{
    font-size: 0.24rem;
    font-weight: 400;
    line-height: 0.34rem;
    color: #BABABA;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 0.1rem;
}
.movieitem .movietxtbox .movietxt2{
    font-size: 0.24rem;
    font-weight: 400;
    line-height: 0.34rem;
    color: #BABABA;
    display: flex;
    margin-bottom: 0.1rem;
}
.movieitem .movietxtbox .movietxt2 div{
    white-space: nowrap;
}
.movieitem .movietxtbox .movietxt2 p{
    display: -webkit-box;    
    -webkit-box-orient: vertical;    
    -webkit-line-clamp: 2;    
    overflow: hidden;
    text-overflow: ellipsis;
}
.movieitem  .movietxtcompanyName{
    font-size: 0.24rem;
    font-weight: 400;
    line-height: 0.34rem;
    color: #BABABA;
    margin-bottom: 0.1rem;
    display: -webkit-box;    
    -webkit-box-orient: vertical;    
    -webkit-line-clamp: 2;    
    overflow: hidden;
    text-overflow: ellipsis;
}

.btnBox{
    display: flex;
    // justify-content: space-between;
    margin-bottom: .22rem;
}
.btnBox .xxbtn{
    height: .48rem;
    font-size: .24rem;
    font-weight: 400;
    color: #FFFFFF;
    background: #AE1F20;
    border-radius: .24rem;
    text-align: center;
    white-space: nowrap;
    line-height: .48rem;
    width: 22%;
    margin-right: 2%;
}


.xmedBox{
    width: 100%;
    box-sizing: border-box;
    margin-bottom: .36rem;
    .xmedBox1{
        width: 100%;
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .xmedtiaoBox{
            width: 5.16rem;
            height: .16rem;
            border-radius: .16rem;
            overflow: hidden;
            background: rgba(238,238,238,0.7);
            .xmedtiao{
                background-image: url('https://zhongyinwh.oss-cn-beijing.aliyuncs.com/scott/pic/组758_1686885321316.png');
                width: 5.16rem;
                height: .16rem;
                background-repeat: no-repeat;
                background-size: 5.16rem .16rem;
                border-radius: .16rem;
                overflow: hidden;
                transition: 1s;
            }
        }
        .xmedTxt{
            font-size: .24rem;
            color: #BABABA;
        }
    }
}



.xmxxbox{
    width: 100%;
    box-sizing: border-box;
    margin-bottom: .36rem;
}
.xmxxbox .ypwlbox{
    width: 100%;
    overflow-x: scroll;
    height: 1.62rem;
}
.ypwlbox::-webkit-scrollbar {
    display: none;
}
.ypwlboxs{
    height: 100%;
    display: flex;
}
.ypwlbox .ypwlboxs .el-image{
    height: 1.62rem;
    width: auto;
    margin-right: .1rem;
    display: inline-table;
}
.ypwlbox .ypwlboxs img{
    height: 1.62rem;
    width: auto;
    object-fit: cover;
    display: block;
    margin-right: .1rem;
}

.xmfxxbox{
    width: 100%;
    margin-bottom: .36rem;
    box-sizing: border-box;
}
.xmfxxbox .xmfxxlist{
    width: 100%;
    overflow-x: scroll;
}
.xmfxxlist::-webkit-scrollbar {
    display: none;
}
.xmfxxbox .xmfxxlist .xmfxxlists{
    display: flex;
}
.cplistTitle{
    font-size: .26rem;
    font-weight: bold;
    color: #343434;
    margin-bottom: .1rem;
}
.cplists{
    display: flex;
    .cplistsItem{
        width: 1.24rem;
        margin-right: .12rem;
        img{
            width: 1.24rem;
            height: 1.24rem;
            display: flex;
        }
        p{
            display: -webkit-box;    
            -webkit-box-orient: vertical;    
            -webkit-line-clamp: 2;    
            overflow: hidden;
            text-overflow: ellipsis;
            font-size: .24rem;
            color: #343434;
        }
    }
}


.boxtitle{
    font-size: 0.34rem;
    font-weight: bold;
    line-height: 0.48rem;
    color: #343434;
    position: relative;
    margin-bottom: 0.2rem;
}
.boxtitle img{
    width: 2.3rem;
    height: 0.54rem;
    position: absolute;
    top: 0.14rem;
    z-index: -1;
}

// .xmxx{
//     width: 100%;
//     display: flex;
//     justify-content: space-between;
//     font-size: 0.24rem;
//     font-weight: 400;
//     line-height: 0.49rem;
//     color: #343434;
//     position: relative;
//     .loginbtn{
//         cursor: pointer;
//         position: absolute;
//         top: 0;
//         left: 0;
//         right: 0;
//         bottom: 0;
//         margin: auto;
//         width: 2.2rem;
//         height: .6rem;
//         text-align: center;
//         line-height: .6rem;
//         color: #fff;
//         background: #A30001;
//         z-index: 888;
//         border-radius: .08rem;
//     }
// }
// .xmxx .xmxx1{
//     width: 60%;
// }
// .xmxx .xmxx2{
//     width: 40%;
// }
// .xmxxhidden{
//     filter: blur(.02rem);
//     -webkit-filter: blur(.02rem);
//     -moz-filter: blur(.02rem);
// }
// .xmxxitemhidden{
//     filter: blur(.08rem);
//     -webkit-filter: blur(.08rem);
//     -moz-filter: blur(.08rem);
// }
// .xmxx div p{
//     display: flex;
// }
// .xmxx div p span{
//     display: inline;
//     white-space: nowrap;
// }
// .xmxx div p span:nth-of-type(2){
//     color: #BABABA;
//     margin-left: 0.1rem;
// }



.topboxbottom{
    width: 100%;
    height: 0.1rem;
    background: #F5F5F5;
}





.navbox{
    width: 100%;
    height: 0.6rem;
    background-color: #fff;
    
}
.nav{
    width: 100%;
    overflow: auto;
    background-color: #fff;
    position: relative;
    z-index: 997;
    border-bottom: 0.02rem solid rgba(112, 112, 112, .2);
}
.nav::-webkit-scrollbar {
    display: none;
}
.navs{
    height: 0.6rem;
    display: flex;
    align-items: center;
}
.navfixed{
    position: fixed;
    top: 1.08rem;
}
.navitem{
    font-size: 0.26rem;
    font-weight: 400;
    line-height: 0.6rem;
    color: #343434;
    white-space: nowrap;
    text-align: center;
    position: relative;
    padding: 0 0.22rem;
}
.navactive{
    color: #A30001;
}
.navxianxx{
    width: 0.46rem;
    height: 0.04rem;
    background: #A30001;
    border-radius: 2.4rem;
    position: absolute;
    bottom: -0.00rem;
    left: calc(0.23rem * 1 + 1.04rem * 0 + 0.52rem - 0.23rem);
    transition: .4s ease;
}




.xsjjbox{
    width: 100%;
    padding: 0.2rem 0.22rem 0;
    box-sizing: border-box;
}
.xsjjtxt{
    width: 100%;
    font-size: 0.24rem;
    font-weight: 400;
    line-height: 0.36rem;
    color: #A1A1A1;
    text-align: justify;
    overflow: hidden;
}







.yyzrbox{
    width: 100%;
    padding: 0.2rem 0.22rem 0;
    box-sizing: border-box;
    .yyzrboxMSG{
        font-size: .24rem;
        color: #A1A1A1;
        line-height: .36rem;
        text-align: center;
    }
}
.yyzrList{
    width: 100%;
    overflow-x: scroll;
    .yyzrLists{
        display: flex;
        .yyzrItem{
            margin-right: .1rem;
            img{
                width: 1.62rem;
                height: 2.24rem;
                display: flex;
                object-fit: cover;
                margin-bottom: .1rem;
            }
            p{
                font-size: .28rem;
                color: #343434;
                line-height: .36rem;
                text-align: center;
            }
            .yyzrItemCastPosition{
                font-size: .24rem;
                color: #9c9c9c;
                line-height: .36rem;
                text-align: center;
            }
        }
    }
}
.yyzrList::-webkit-scrollbar {
    display: none;
}



// .gwitemlist{
//     width: 100%;
//     overflow: hidden;
//     font-size: 0.24rem;
//     font-weight: 400;
//     line-height: 0.36rem;
//     color: #343434;
//     text-align: justify;
// }
// .gwitemlist::-webkit-scrollbar {
//     display: none;
// }
// .gwitemlists{
//     height: 2.76rem;
//     display: flex;
//     align-items: center;
    
// }
// .gwitem{
//     width: 1.62rem;
//     background: #FFFFFF;
//     margin-right: 0.1rem;
//     position: relative;
// }
// .gwitemlist .gwitemlists .gwitem img{
//     display: block;
//     width: 1.62rem;
//     height: 2.24rem;
//     margin-bottom: 0.1rem;
// }
// .gwitemlist .gwitemlists .gwitem .gwname{
//     width: 100%;
//     font-size: 0.28rem;
//     font-weight: 400;
//     line-height: 0.36rem;
//     color: #343434;
//     text-align: center;
//     white-space: nowrap;
//     overflow: hidden;
//     // text-overflow: ellipsis;
// }



// .cglcbox{
//     width: 100%;
//     padding-top: 0.2rem;
// }
// .cglcbox .boxtitle{
//     margin-left: 0.2rem;
// }
// .cglcbox .cglcimg{
//     width: 7.06rem;
//     height: 3.08rem;
//     margin: 0 auto 0.2rem;
// }





// .zzcxbox{
//     width: 100%;
//     padding: 0.2rem 0.22rem 0;
//     box-sizing: border-box;
// }
// .zzcxbox .zzcximgbox{
//     width: 100%;
//     display: flex;
//     flex-wrap: wrap;
//     justify-content: center;
// }
// .zzcxbox .zzcximgbox img{
//     height: 2.5rem;
//     object-fit: contain;
// }


.xgyxbox{
    width: 100%;
    padding: 0.2rem 0.22rem 0;
    box-sizing: border-box;
    .xgyxList{
        width: 100%;
        font-size: 0.24rem;
        font-weight: 400;
        line-height: 0.36rem;
        color: #A1A1A1;
        text-align: justify;
        overflow: hidden;
        img{
            width: 100%;
            height: auto;
            object-fit: cover;
        }
    }
}

// .newitmebox{
//     width: 100%;
//     height: 100%;
//     display: flex;
//     justify-content: space-between;
// }

// .xgyxbox .newsitem{
//     width: 100%;
//     height: 1.7rem;
//     padding-bottom: 0.1rem;
//     margin-bottom: 0.2rem;
//     border-bottom: 0.01rem solid #F4F4F4;
// }
// .xgyxbox .newsitem .newsitemleft{
//     width: 4rem;
// }
// .xgyxbox .newsitem .newsitemleft .newstitle{
//     width: 3.9rem;
//     font-size: 0.26rem;
//     font-weight: 400;
//     line-height: 0.4rem;
//     color: #343434;
//     margin-bottom: 0.1rem;
//     display: -webkit-box;    
//     -webkit-box-orient: vertical;    
//     -webkit-line-clamp: 2;    
//     overflow: hidden;

// }
// .xgyxbox .newsitem .newsitemleft .newstime{
//     font-size: 0.24rem;
//     font-weight: 400;
//     line-height: 0.32rem;
//     color:#BABABA;
//     white-space: nowrap;
// }
// .xgyxbox .newsitem .newsitemleft .newstime span{
//     display: inline-block;
//     transform: scale(.84);
//     transform-origin:0 0; 
// }
// .newsitemright{
//     width: 2.88rem;
//     height: 100%;
//     overflow: hidden;
//     border-radius: 0.08rem;
// }
// .newsitemright img{
//     width: 100%;
//     height: 100%;
// }
// .newsxian{
//     width: 7rem;
//     height: 0.02rem;
//     background-color:#F4F4F4;
//     margin: 0 auto 0.2rem;
// }


.rmxmbox{
    width: 100%;
    box-sizing: border-box;
}
.zzcxList{
    width: 100%;
    font-size: 0.24rem;
    font-weight: 400;
    line-height: 0.36rem;
    color: #A1A1A1;
    text-align: justify;
    overflow: hidden;
    img{
        width: 100%;
        height: auto;
        object-fit: cover;
        display: block;
    }
}
// .rmxmlist{
//     width: 100%;
//     overflow: auto;
// }
// .rmxmlist::-webkit-scrollbar {
//     display: none;
// }
// .rmxmlists{
//     height: 3.16rem;
//     display: flex;
//     align-items: center;
// }
// .rmxm{
//     width: 1.86rem;
//     background: #FFFFFF;
//     margin-right: 0.12rem;
//     position: relative;
// }
// .rmxmlist .rmxmlists .rmxm img{
//     display: block;
//     width: 1.86rem;
//     height: 2.62rem;
//     margin-bottom: 0.1rem;
// }
// .rmxmlist .rmxmlists .rmxm .gwname{
//     width: 100%;
//     font-size: 0.28rem;
//     font-weight: 400;
//     line-height: 0.36rem;
//     color: #343434;
//     text-align: center;
//     white-space: nowrap;
//     overflow: hidden;
// }








.cgdybox{
    width: 100%;
    padding: 0.2rem 0.22rem 0.2rem;
    box-sizing: border-box;
    .ypjdList{
        .ypjdItem{
            position: relative;
            overflow: hidden;
            margin-bottom: .14rem;
            .ypjdItemTop{
                width:100%;
                display: flex;
                overflow: hidden;
                position: relative;
                height: .34rem;
                .ypjdItemTopPoint{
                    width: .26rem;
                    height: .26rem;
                    background: rgba($color: #AE1F20, $alpha: .33);
                    border-radius: 50%;
                    overflow: hidden;
                    position: relative;
                    margin-left: .4rem;
                    margin-right: .2rem;
                    margin-top: .04rem;
                    .ypjdItemTopPointS{
                        width: .14rem;
                        height: .14rem;
                        border-radius: 50%;
                        background: #AE1F20;
                        margin: auto;
                        inset: 0;
                        position: absolute;
                    }
                }
                .ypjdItemTopPoint2{
                    width: .26rem;
                    height: .26rem;
                    background: rgba($color: #C0C0C0, $alpha: .33);
                    border-radius: 50%;
                    overflow: hidden;
                    position: relative;
                    margin-left: .4rem;
                    margin-right: .2rem;
                    margin-top: .04rem;
                    .ypjdItemTopPointS2{
                        width: .14rem;
                        height: .14rem;
                        border-radius: 50%;
                        background: #C0C0C0;
                        margin: auto;
                        inset: 0;
                        position: absolute;
                    }
                }
                p{
                    font-size: .24rem;
                    font-weight: bold;
                    color: #343434;
                    line-height: .34rem;
                }

            }
            .ypjdItemBottom{
                width:100%;
                display: flex;
                overflow: hidden;
                position: relative;
                .ypjdItemBottomLine{
                    width: .02rem;
                    background-color: #CACACA;
                    margin-left: .52rem;
                    margin-right: .32rem;
                }
                .ypjdItemBottomP{
                    padding: .1rem 0 .34rem 0;
                    font-size: .24rem;
                    color: #A1A1A1;
                    line-height: .36rem;
                }
            }



        }
    }
}


// .cgdyitembox{
//     width: 100%;
//     height: 5rem;
//     overflow: auto;
//     font-size: 0.24rem;
//     font-weight: 400;
//     line-height: 0.34rem;
//     color: #343434;
//     text-align: justify;
//     margin-bottom: 0.32rem;
//     padding-right: 0.1rem;
// }
// .cgdyitembox::-webkit-scrollbar {
//     width: 0.1rem;
//     height: 0.02rem;
// }
// .cgdyitembox::-webkit-scrollbar-thumb {
//     border-radius: 0.05rem;
//      -webkit-box-shadow: inset 0 0 0.1rem rgba(0,0,0,0.2);
//     background: #a0a0a0;
// }
// .cgdyitembox::-webkit-scrollbar-track {
//     -webkit-box-shadow: inset 0 0 0.1rem rgba(0,0,0,0.2);
//     border-radius: 0.05rem;
//     background: #EDEDED;
// }
// /* .cgdyitembox::-webkit-scrollbar {
//     display: none;
// } */
// .cgdyitem .wen{
//     display: flex;
//     margin-bottom: 0.1rem;
// }
// .cgdyitem .wen img{
//     display: block;
//     width: 0.34rem;
//     height: 0.34rem;
//     margin-right: 0.16rem;
// }
// .cgdyitem .da{
//     display: flex;
//     margin-bottom: 0.1rem;
// }
// .cgdyitem .da img{
//     display: block;
//     width: 0.34rem;
//     height: 0.34rem;
//     margin-right: 0.16rem;
// }






.close-searchbox{
    display: none;
}
.searchbox{
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    z-index: 999;
    background-color: rgba(0,0,0,.5);
    transition: 1s ease;
    .close-modal-dialog{
        display: none;
    }

    .modal-dialog{
        position: fixed;
        z-index: 1000;
        top: 25%;
        left: 3%;
        width: 7.1rem;
        height: 7.5rem;
        overflow: hidden;
        font-size: 0.28rem;
        transition: 1s ease;
        .modal-content{
            position: relative;
            background-color: #fff;
            background-clip: padding-box;
            border: 1px solid rgba(0,0,0,.2);
            border-radius: 6px;
            box-shadow: 0 3px 9px rgb(0 0 0 / 50%);
            outline: 0;
            .modal-header{
                background-color: #A30001;
                font-weight: 700;
                color: #fff;
                padding: 15px;
                border-bottom: 1px solid #e5e5e5;
                box-sizing: border-box;
                text-align: center;
                border-radius: 6px 6px 0 0;
                font-size: 0.34rem;
                .close{
                    margin-top: -2px;
                    padding: 0;
                    cursor: pointer;
                    background: 0 0;
                    border: 0;
                    appearance: none;
                    float: right;
                    font-size: 21px;
                    font-weight: 700;
                    line-height: 1;
                    color: #000;
                    text-shadow: 0 1px 0 #fff;
                    opacity: .2;
                }
            }
            .modal-body{
                position: relative;
                padding: 0.3rem;
                box-sizing: border-box;
                text-align: center;
                .input-group{
                    width: 100%;
                    margin-bottom: 0.4rem;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    position: relative;
                    border-collapse: separate;
                    span{
                        color: #A30001;
                        padding-right: 20px;
                        font-size: 0.32rem;
                    }
                    input{
                        padding: 5px 10px;
                        border: 1px solid #efefef;
                        outline: none;
                        width: 4.8rem;
                        height: 0.4rem;
                        font-size: 0.28rem;
                    }
                    #p{
                        width: 2rem;
                    }
                    .sendcode{
                        width: 2.8rem;
                        height: 0.64rem;
                        font-size: 0.32rem;
                        line-height: 0.64rem;
                        text-align: center;
                        color: #A30001;
                    }
                }
                .btn{
                    text-align: center;
                    margin-left: auto;
                    margin-right: auto;
                    padding: 0.15rem 0.6rem;
                    font-size: 0.32rem;
                    background-color: #A30001;
                    display: inline-block;
                    color: #fff;
                    border-radius: 0;
                    font-weight: 500;
                    touch-action: manipulation;
                    cursor: pointer;
                    background-image: none;
                    border: 1px solid transparent;
                    white-space: nowrap;
                    vertical-align: middle;
                    margin-bottom: 0;
                    user-select: none;
                    border-radius: 0.08rem;
                }
            }
            .modal-footer{
                padding: 0.3rem;
                border-top: 1px solid #e5e5e5;
                box-sizing: border-box;
                text-align: center;
            }
        }
    }
}

.toxmrg{
    width: 100%;
    height: .82rem;
    background: #A30001;
    font-size: .3rem;
    color: #FFFFFF;
    line-height: .82rem;
    text-align: center;
    position: fixed;
    bottom: 0;
    z-index: 999;
    a{
        display: block;
        width: 100%;
        height: 100%;
    color: #FFFFFF;

    }
}

</style>